@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Sen&display=swap');

body{
  @apply bg-gradient-to-r from-cyan-200 to-blue-300
}
html, * {
    font-family: sen;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

input:focus, select:focus {
  @apply outline-none;
}

.boarding {
  background-image: linear-gradient(
      70deg,
      rgba(10, 40, 40, 0.1),
      rgba(10, 40, 40, 0.2)
    ),
    url(https://wallpaperaccess.com/full/1508713.jpg);
  @apply bg-no-repeat bg-center;
}


.loader{
    animation: loader 1s ease-in 0s infinite normal forwards;
  } 


  .slide-up{
    animation: slide-up 60ms ease-in 20ms 1 forwards alternate;
  }

  @keyframes slide-up {
    from{
      @apply -bottom-10 -right-10
    }
  
    to{
      @apply bottom-5 right-4
    }
  }
  
@keyframes loader {
    from {
      width: 10px;
      height: 5px;
      background-color: white;
    }
    to {
      width: 180px;
      height: 5px;
      background-color: orange;
     border-radius: 2%;
    }
  }